// /*
//  * @Author: Team GMF
//  */

// class Navigation {
// 	init() {
// 		if (typeof window.debugMode !== 'undefined' && window.debugMode === 'Development') console.log('Navigation Init');
// 		let timeout;
// 		// Listen to resize event
// 		window.addEventListener(
// 			'resize',
// 			(event) => {
// 				if (timeout) {
// 					window.cancelAnimationFrame(timeout);
// 				}
// 				// Setup the new requestAnimationFrame()
// 				timeout = window.requestAnimationFrame(() => {
// 					this.render();
// 				});
// 			},
// 			false
// 		);

// 		// Only for page menu
// 		window.onload = function () {
// 			document.addEventListener("click", function (event) {
// 				// if the clicked element isn't child of the navbar, you must close it if is open
// 				if (!event.target.closest("#nav-menu") && document.getElementById("nav-menu-section").classList.contains("show")) {
// 					document.getElementById("nav-menu-btn").click();
// 				}
// 			});
// 		};
// 	}
// 	render() {
// 		const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
// 		if (viewportWidth <= 991) {
// 			document.querySelectorAll('.nav-item.level-1.active.dropdown:not(.show)').forEach((el) => {
// 				el.classList.add('show');
// 				el.childNodes.forEach((child) => {
// 					if (child.nodeType !== 3 && child.classList.contains('dropdown-menu')) {
// 						child.classList.add('show');
// 					}
// 				});
// 			});
// 		} else {
// 			document.querySelectorAll('.nav-item.level-1.active.dropdown.show').forEach((el) => {
// 				el.classList.remove('show');
// 				el.childNodes.forEach((child) => {
// 					if (child.nodeType !== 3 && child.classList.contains('dropdown-menu')) {
// 						child.classList.remove('show');
// 					}
// 				});
// 			});
// 		}
// 	}
// }

// const NavigationInstance = new Navigation();
// document.addEventListener('DOMContentLoaded', () => {
// 	NavigationInstance.init();
// });

// if (typeof window.debugMode !== 'undefined' && window.debugMode === 'Development') console.log('Navigation default compiled');
